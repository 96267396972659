.PopupWindow {
  margin: 10% auto;
  width: 30%;
  background-color: white;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  padding: 15px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(56, 56, 56, 0.6);
}

.copy {
  margin-left: 80%;
  margin-top: 2.5%;
}

@media (max-width: 1100px) {
  .PopupWindow {
    width: 50%;
  }

  .copy {
    margin-left: 85%;
  }
}

@media (max-width: 750px) {
  .PopupWindow {
    width: 60%;
  }
}