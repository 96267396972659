.wrapper {
  display: grid;
  grid-template-columns: 15% auto 15%;
}

.navigation-section {
  align-self: start;
  padding-top: 15px;
  display: inline-flex;
  align-items: center;

  .logo {
    font-family: "Aclonica";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #0c0b0b;
  }
}

.title-section {
  place-self: center;
  padding-top: 30px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #000000;

  .title-with-aclonica-font {
    font-family: "Aclonica";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    color: #0c0b0b;
  }

  .title-with-description {
    display: flex;
    flex-direction: column;
    place-self: center;
    place-items: center;

    .description {
      font-family: "Lato";
      font-style: italic;
      font-weight: 400;
      width: 820px;
      font-size: 20px;
    }
  }

  .title-with-image {
    display: flex;
    place-self: center;
    place-items: center;
    gap: 30px;

    img {
      height: auto;
      width: 90px;
    }
  }
}

.user-section {
  margin: 15px 15px 0 0;
  justify-self: end;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-content: flex-start;

  .user-avatar {
    margin: 0;
    font-family: "Roboto";
    font-size: 16px;
    color: black;
    text-align: center;
  }

  .user-avatar-name {
    margin-top: 2px;
    font-family: "Lato";
    font-size: 16px;
    color: black;
    text-align: center;
  }
}
