.trove__header__background {
  background-image: url("../../assets/learning1.png");
  background-repeat: no-repeat;
  background-size: cover; //causes pixelation
  background-position: center center;
  min-height: max-content;
  position: relative;
}

.trove__header__hamburger {
  position: absolute;
  top: 15px;
  left: 15px;
}

.trove__header__centerContent {
  display: grid;
  place-items: center;
}

.trove__header__title {
  margin-top: 1rem;
  display: flex;
  place-content: center;
  flex-direction: row;
  font-family: "Aclonica", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: clamp(3rem, 5vw, 6rem);
  -webkit-text-stroke: 2.5px rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  align-items: center;
}

.trove_header__text1 {
  margin-top: 1rem;
}

.trove_header__img {
  max-width: 20%;
  height: auto;
  object-fit: contain;
}

.trove__header__subHeader {
  font-family: "Lato", sans-serif;
  font-weight: 1000;
  font-size: clamp(1rem, 2.2vw, 2.25rem);
  margin: 0rem;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.trove__header__search {
  display: grid;
  place-items: center;
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.input_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e5e2e2;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  min-width: 48rem;
  height: auto;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

  .search_bar_button {
    background-color: #e5e2e2;
    border: none;
    box-shadow: none;
    cursor: pointer;
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    background-image: url("../../assets/searchIcon.png");
  }

  input {
    margin: 0 0.5rem 0 0.5rem;
    width: 100%;
    border: none;
    outline: none;
    color: #636060;
    font-family: Lato;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    background: #e5e2e2;
  }
}

@media (max-width: 850px) {
  .input_wrapper {
    width: 90%;
    min-width: unset;
  }
}
