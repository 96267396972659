.search_result {
  border: 1px solid #c4c4c4;
  padding: 20px;
  margin: 14px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 20px;

  .search_result_image {
    margin: 0 auto;
    width: 370px;
    height: 180px;
    object-fit: contain;
  }

  .search_result_title_section {
    .search_result_title {
      font-family: "Public Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-decoration-line: underline;
      color: #007aaa;
    }

    .search_result_stars {
      display: flex;
    }

    .search_result_subtitle {
      font-family: "Lato";
      font-size: 20px;
      color: #5e5b5b;
    }

    .search_result_category_section {
      display: flex;
      flex-wrap: wrap;
      gap: 3px;

      .search_result_category {
        display: inline-block;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #007aaa;
        border-radius: 5px;
        padding: 6px 12px;
        color: #fff;
        text-align: center;
      }
    }
  }

  .search_result_review_section {
    align-self: center;
    display: flex;
    gap: 6px;
    color: black;

    .search_result_review_image {
      align-self: start;
    }

    .search_result_review_paragraph {
    }
  }
}
