.wrapper {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.inappropriate {
  font-family: "Lato";
  font-style: italic;
  font-size: 14px;
  color: #000000;
  align-items: center;
  padding-bottom: 1.3%;
}

.reviewbuttons {
  display: grid;
  gap: 2%;
  grid-template-columns: 3.26% 5.26%;
  margin-top: 5px;
}

.helpfultext {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #000000;
  margin-top: 3px;
}

.helpful {
  box-sizing: border-box;
  white-space: pre-wrap;
  /* Auto layout */
  min-width: 50px;
  max-width: fit-content;
  max-height: 15px;
  font-size: 0.5em;
  color: #000000;
  border: #000000;
  background: #fbfcfc;
  padding-top: 0.25em;
}

.nothelpful {
  box-sizing: border-box;
  white-space: pre-wrap;
  /* Auto layout */
  min-width: 50px;
  max-width: fit-content;
  max-height: 15px;
  font-size: 0.5em;
  color: #fbfcfc;
  border: #000000;
  background: #3174b3;
  padding-top: 0.25em;
}

.inappropriate {
  box-sizing: border-box;
  white-space: pre-wrap;
  /* Auto layout */
  min-width: 80px;
  max-width: fit-content;
  max-height: 15px;
  font-size: 0.5em;
  color: #000000;
  border: none;
  background: #fbfcfc;
  padding-top: 0.25em;
}

.thumbnail {
  margin: 0 auto;
  width: 360px;
  height: 180px;
  object-fit: contain;
}

.link {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: #007aaa;
  margin: auto 0 auto 40px;
}

.courseTitle {
  text-decoration: underline;
}

.topmost-container {
  display: grid;
  grid-template-columns: 25% 75%;
}

.buttons {
  display: flex;
  gap: 2%;
  margin-top: 25px;

  .button {
    box-sizing: border-box;
    white-space: pre-wrap;
    /* Auto layout */
    height: 100%;
    min-width: 100px;
    max-width: fit-content;
    font-size: 0.75em;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #138cfc;

    &:hover {
      opacity: 0.7;
    }
  }

  .edit_icon,
  .trash_icon {
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

@media (max-width: 1200px) {
  .reviewbuttons {
    gap: 3%;
    grid-template-columns: 3.26% 5.26%;
    margin-top: 5px;
  }
}
@media (max-width: 1000px) {
  .reviewbuttons {
    gap: 7%;
    grid-template-columns: 3.26% 5.26%;
    margin-top: 5px;
  }
}
@media (max-width: 600px) {
  .reviewbuttons {
    gap: 10%;
    grid-template-columns: 3.26% 5.26%;
    margin-top: 5px;
  }
}
.arrowImg {
  width: 13px;
  height: 13px;
  align-items: right;
}

.content-data {
  display: flex;
  flex-direction: column;
  color: black;
  font-family: "Lato";
  font-style: normal;
  font-size: 20px;
  margin-top: 10px;
}

.description {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-top: 10px;
  color: #000000;
}

.paragraph {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 5px;
}

.reviewer-profile {
  display: flex;
  gap: 26px;
  margin-top: 20px;
}

.reviews-sort-text {
  display: flex;
  position: absolute;
  flex-direction: row;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 12px;
  gap: 3px;
  color: #000000;
  align-items: center;
  justify-content: right;
  left: 85%;
}

.reviews-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-top: 50px;
  line-height: 29px;
  color: #000000;
  align-items: center;
}

.review-pfp {
  height: 65px;
  border-radius: 50%;
}

.reviewersName {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #5e5b5b;
  align-self: center;
}

.star-review {
  display: flex;
  gap: 16.3px;
  align-items: center;

  .edit_icon {
    padding-left: 5%;
  }
}

.stars {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 0.7px;
}

.review-date {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  align-self: center;
  color: #000000;
}

.price {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding-top: 5px;
  color: #000000;
}

.platform {
  padding-bottom: 1.3%;
}

.duration {
  padding-top: 1.3%;
}

.arrowImg {
  width: 9px;
  height: 9px;
  align-items: right;
}
.dropBtn {
  //styleName: web/H6;
  font-family: Public Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  padding: 2px;
  text-align: center;
  -webkit-appearance: none; // removes dropdown arrow
}
.dropBtnField {
  font-family: Public Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}
.delete_failure {
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  font-family: "Public Sans";
  font-size: 20px;
  color: white;
  background-color: #b43535;
  border-radius: 5px;
  border: 1px solid #b44c4c;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}
