.topmost-container {
  display: flex;
  margin-left: 40px;
  margin-top: 50px;
}

.thumbnail {
  width: 360px;
  height: 180px;
  object-fit: contain;
}

.CourseInfo {
  align-self: center;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: #007aaa;
  margin-left: 10px;
}

.Platform {
  color: black;
  font-family: "Lato";
  font-style: normal;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  margin-top: 10px;
}
