.PopupWindow {
  margin: 10% auto;
  width: 40%;
  background-color: white;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  padding: 15px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(56, 56, 56, 0.6);
}

@media (max-width: 2000px) {
  .PopupWindow {
    margin-top: 2%;
    width: 80%;
  }
}

h2 {
  margin-top: 10px;
}

.GuidelinesButton {
  padding-left: 1px;
  box-shadow: none;
  border: none;
  background-color: white;
  color: #0b23f8;
  text-decoration: underline;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.GuidelinesButton:hover,
.GuidelinesButton:focus {
  background-color: white;
  box-shadow: none;
  border: none;
}

.CloseButton {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  line-height: 20px;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: white;
}

.CloseButton:hover,
.CloseButton:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: white;
}