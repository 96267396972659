.courses_description {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 20px 0px 0px 0px;
  color: #000000;
  margin-bottom: 35px;

  .search_link {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-indent: 8px;
    color: #007aaa;
    text-decoration-line: underline;
  }
}

.courses_container {
  padding: 7px 7px 29% 7px;
  background-color: #f7f4f4;
}

table {
  border-collapse: separate;
  border-spacing: 0px 10px;
  width: 100%;

  /* Table header */
  th {
    text-align: left;
    line-height: 100%;
    background-color: white;
    padding: 7px;

    font-family: "Lato";
    font-style: bold;
    font-weight: 800;
    font-size: 20px;
  }
  td {
    line-height: 100%;
    text-align: left;
    font-size: 14px;
    background-color: white;
    padding: 8px;

    .item_course_URL {
      font-size: 14px;
      color: #007aaa;
      text-decoration-line: underline;
    }

    .item_platform {
      font-size: 16px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
    }

    .item_date_reviewed {
      font-size: 16px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
    }
  }
}

/* Globally for the URL */
a {
  color: #007aaa;
  text-decoration: underline;
}

/* Each state */
a:visited {
  text-decoration: none;
  color: #007aaa;
}
a:hover {
  text-decoration: none;
  color: #007aaa;
}
a:focus {
  text-decoration: none;
  color: black;
}
a:hover,
a:active {
  text-decoration: none;
  color: black;
}

/* Display the stars in a row. */
.item_row_display {
  display: flex;
}
