.table {
  margin: 0 1% 0 17%;

  .first_row {
    display: grid;
    grid-template-columns: 16% 50% 12% 5% auto;
    column-gap: 1.5%;

    font-family: "Public Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    .label_sortable {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);

      p {
        display: flex;
        align-items: center;
      }
      img {
        width: 9px;
      }
      .down_arrow {
        transform: rotate(180deg);
      }
    }
    .label {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }
  .course_row {
    display: grid;
    grid-template-columns: 16% 50% 12% 5% auto;
    column-gap: 1.5%;

    padding: 4px 0 4px 0;

    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    .stars_flex {
      display: flex;
      height: 22px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .table {
    margin-left: 11%;
  }
}

@media screen and (max-width: 1100px) {
  .table {
    margin-left: 9%;

    .first_row,
    .course_row {
      grid-template-columns: 16% 35% 12% 7% auto;
      column-gap: 3%;
    }
  }
}
