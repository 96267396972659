.search_wrapper {
  padding: 0 30px 0 30px;
}
.search_sort_header {
  display: flex;
  flex-direction: row;
  font-family: 'Lato';
  font-size: 17px;
  color: #000000;
  gap: 5px;
  line-height: 13px;
  align-items: center;
  justify-content: space-between;
}
.search_header {
  font-family: "Lato";
  font-size: 20px;
  color: #000000;
}

.react_link {
  text-decoration: none;
}

.search_footer {
  .search_footer_button {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #138cfc;
    /* primary-light/ui-primary-light-10 */

    border: 1px solid rgba(255, 255, 255, 0.1);
    /* light/shadow/shadow-level-1 */

    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    color: white;

    padding: 6px 12px;
    margin: 0 10px 0 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
}

.paging_navigation {
  display: flex;
  gap: 8px;
  margin-bottom: 25px;

  button {
    background: none;
    color: inherit;
    box-shadow: none;
    border: 1px solid #b0bac9;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .total_page_num {
    place-self: center;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}
.arrowImg {
  width: 9px;
  height: 9px;
  align-items: right;
}

.dropBtnField{
  display: flex;
  flex-direction: row;
  font-family: Public Sans;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  gap: 1px;
}
.dropBtn {
  //styleName: web/H6;
  font-family: Public Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  outline: none; // removes blue highlight feature
  border: none;
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  padding: 2px;
  text-align: center;
  -webkit-appearance: none; // removes dropdown arrow
}
