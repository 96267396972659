.wishlist_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 70px;
  margin: 1%;
  place-items: center;
}

//for desktop screens 4k display
@media screen and (min-width: 1800px) {
  .wishlist_grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    place-items: center;
  }
}

.wishlist_grid .wishlist_item_image {
  margin: 0 auto;
  width: 370px;
  height: 180px;
  object-fit: contain;
}

.wishlist_grid .wishlist_item_description {
  display: flex;
  justify-content: space-between;
}

.wishlist_grid .wishlist_item_description .course_link {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #007aaa;
  margin: 10px 5px 0px 0px;
  text-decoration-line: underline;
  line-height: normal;
}

h4 {
  background-color: white;
}

.wishlist_grid .wishlist_item_description .wishlist_item_date {
  text-align: left;
  font-family: "Lato";
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  color: black;
  margin: 10px 5px 0px 0px;
}

.wishlist_grid .wishlist_item_description .trash_icon {
  justify-content: end;
  margin: 5px 5px 0px 0px;
}

.wishlist_grid .wishlist_items_none {
  display: flex;
  padding-top: 10px;
  font-size: 14px;
}

.trash_icon:hover {
  cursor: pointer;
}
