.table {
  margin-top: 5%;

  .first_row {
    display: grid;
    grid-template-columns: 2% 12% 8% 7% 6% 7% 7% 6%;
    column-gap: 1.5%;

    font-family: "Public Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    .label_sortable {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);

      img {
        width: 9px;
      }

      .sort_image_rotated {
        transform: rotate(180deg);
      }
    }
    .label {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }
  .employee_row {
    display: grid;
    grid-template-columns: 2% 12% 8% 7% 6% 7% 7% 6%;
    column-gap: 1.5%;

    padding: 8px 0 8px 0;

    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    img {
      place-self: center end;
    }
    .employee_name_column {
      text-align: left;
    }
  }
  hr {
    margin: 0 1% 0 1%;
    border: 1px solid rgba(217, 217, 217, 0.78);
  }
}

@media screen and (max-width: 1500px) {
  .table {
    .first_row,
    .employee_row {
      grid-template-columns: 2% 12% 9% 8% 7% 9% 7% 7%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .table {
    .first_row,
    .employee_row {
      grid-template-columns: 2% 12% 10% 9% 8% 10% 7% 7%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .table {
    .first_row,
    .employee_row {
      grid-template-columns: 2% 12% 12% 10% 9% 12% 8% 8%;
      column-gap: 3%;
    }
  }
}
