.card_container {
  background: #fffdfd;
  border: 1px solid #8c8989;
  border-radius: 40px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  text-decoration: none;
  max-width: 100%;

  .card_header {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;

    h3 {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 1.1rem;
      margin: 0;
      color: #000000;
    }
  }

  .card_title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    margin: 0;
    color: #000000;
  }

  .card_image {
    margin: 0 auto;
    width: 195px;
    height: 110px;
    object-fit: contain;
  }

  .card_stars {
    display: flex;
    max-width: 100%;
  }

  .card_review {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: #000000;
  }

  .card_link {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 0.9rem;

    color: #0a81d8;
  }
}
