.InputBox {
  padding: 31px 0px 0px 0px;
}

.InputBox,
.SubmitButton {
  max-width: 500px;
  margin-left: 40px;
  display: grid;
  text-align: left;
}

.InputBox label,
.InputBox span {
  font-family: "Lato";
  float: left;
}

.InputBox input,
.InputBox select {
  width: 500px;
  font-family: "Public Sans";
  font-size: 14px;
  padding: 11px 8px;
  border-radius: 2px;
  border: 1px solid #bbbbbb;
  margin-top: 5px;
  margin-bottom: 5px;
}

.InputBox input:focus {
  outline: none;
  border-color: #000000;
  background-color: #e9f6ff;
}

.SubmitButton {
  grid-template-columns: 70% auto;
  padding: 30px 0px;
}

.ErrorText {
  display: grid;
  color: #b40e0e;
  font-family: "Lato";
}

.PlatformInput {
  display: grid;
  border-radius: 2px;
  border: 1px solid #bbbbbb;
  padding: 10px;
  max-width: 500px;
}

.PlatformInput label {
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 700;
  padding: 5px 0px 10px;
}

.PlatformInput input,
.PlatformInput select {
  padding: 8px 8px;
  width: 480px;
}

.PlatformInput button {
  margin-top: 6px;
  max-width: 35 px;
  float: right;
}

button {
  font-family: "Public Sans";
  font-size: 14px;
  background-color: #3174b3;
  color: white;
  border: 1px solid #4580b7;
  padding: 10px 12px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #2c679f;
  border: 1px solid #3d72a4;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background: #94a8b9;
  color: white;
  border-color: #ccc;
  cursor: not-allowed;
}

.ImageUploadButton {
  display: flex;
  margin-left: 40px;
}

.ImageMessage {
  margin-top: 10px;
  margin-left: 40px;
  margin-bottom: -20px;
  color: #6c6c6c;
  font-style: italic;
}

.FormSuccess {
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 10px;
  padding: 10px;
  text-align: left;
  font-family: "Public Sans";
  font-size: 20px;
  color: white;
  background-color: #85c488;
  border-radius: 5px;
  border: 1px solid #69a46c;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

.FormFailure {
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 10px;
  padding: 10px;
  text-align: left;
  font-family: "Public Sans";
  font-size: 20px;
  color: white;
  background-color: #b43535;
  border-radius: 5px;
  border: 1px solid #b44c4c;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 700px) {
  .InputBox input,
  .PlatformInput input {
    max-width: 450px;
  }
}

.not_authorized {
  margin-top: 20px;
  text-align: center;
}