.PopupWindow {
  margin: 10% auto;
  width: 30%;
  background-color: white;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  padding: 15px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(56, 56, 56, 0.6);
}

.trash_icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.buttons {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 5px;
  margin-top: 10px;
  margin-left: 55%;
}

@media (max-width: 1180px) {
  .buttons {
    grid-template-columns: 50% 50%;
    margin-left: 50%;
  }
}

@media (max-width: 1000px) {
  .buttons {
    margin-left: 40%;
  }
}

@media (max-width: 850px) {
  .buttons {
    margin-left: 20%;
  }
}

.cancel {
  background-color: #eaeaea;
  color: black;
  border: 1px solid #c9c9c9;
}

.cancel:hover {
  background-color: #d7d7d7;
  color: black;
  border: 1px solid #c0c0c0;
}
