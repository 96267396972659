/* A dummy class to solve the issue of the first class in the file 
not being properly rendered on the deployed app */
.CSSBuffer {
    display: flex;
}

.UploadPrompt {
    padding-top: 30px !important;
    font-family: "Lato" !important;
    font-weight: 400 !important;
    display: flex !important;
}

.UploadButton {
    margin-left: 10px;
    width: 30px; 
    height: auto;
}

.UploadButton:hover {
    cursor: pointer;
}

.ErrorText {
    color: #b40e0e;
    margin-top: 5px;
  }