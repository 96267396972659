.review_input {
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 700px;
  margin-top: 5px;
  resize: none;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #bbbbbb;
}

.review_input:focus {
  outline: none;
  border-color: #000000;
}

.CharacterCount {
  margin-left: 650px;
  font-size: 14px;
  color: gray;
}

.ErrorText {
  display: grid;
  color: #b40e0e;
}

@media (max-width: 850px) {
  .review_input {
    width: 80%;
  }

  .CharacterCount {
    margin-left: 75%;
  }
}
