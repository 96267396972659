.not_authorized {
  text-align: center;
}

.section_header {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  grid-column: 1/-1;
}

.admin_functions {
  display: grid;
  grid-template-columns: 30% 35%;
  column-gap: 10%;
  row-gap: 20%;
  margin-top: 20px;
  margin-left: 5%;
}

.admin_manage {
  grid-column: 1;

  .table {
    margin-top: 5%;
    max-width: 95%;
    margin-left: 3%;
    .first_row {
      display: grid;
      grid-template-columns: 60% 35%;
      column-gap: 5%;

      font-family: "Public Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      .label_sortable {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);

        img {
          width: 9px;
        }

        .sort_image_rotated {
          transform: rotate(180deg);
        }
      }
      .label {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      }
    }
  }

  .employee_row {
    display: grid;
    grid-template-columns: 60% 35%;
    column-gap: 5%;

    padding: 8px 0 8px 0;

    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    .employee_name_column {
      text-align: left;
    }
  }

  @media screen and (max-width: 1000px) {
    .table {
      .first_row,
      .employee_row {
        grid-template-columns: 65% 30%;
        column-gap: 3%;
      }
    }
  }
}

.admin_add {
  grid-column: 2;
  grid-template-columns: 85% 15%;
  grid-template-rows: 40px 60px;
  column-gap: 5px;
  display: grid;

  .dropdown {
    margin-top: 10px;
    align-items: center;
  }
  .add_button {
    max-height: 38px;
    max-width: 60px;
    align-self: center;
  }
}

.content_report {
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #007aaa;
  text-align: center;
  text-decoration-line: underline;
  padding-top: 12px;
}

.empty_message {
  margin-top: 5px;
  text-align: center;
}
