.course_carousel {
  max-width: 70rem;
  display: block;
  margin: 0 auto;

  .carousel_header {
    text-align: center;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1rem, 2.2vw, 2.25rem);
    margin-top: 18px;
  }

  .carousel_cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: space;
    column-gap: max(3rem, 4vw);
    margin-top: max(2rem, 3vw);
  }

  @media only screen and (max-width: 800px) {
    .carousel_cards {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 9px;

  .circle_empty {
    cursor: pointer;
    display: block;
	  border-radius: 50%;
	  height: 25px;
	  width: 25px;
	  background: transparent;
    border: 1px solid #000000;
	}

  .circle_filled {
    cursor: pointer;
    display: block;
	  border-radius: 50%;
	  height: 25px;
	  width: 25px;
	  background: #000000;
    border: 1px solid #000000;
	}
}