.category-section {
  background-color: #f7f4f4;
  max-width: 70rem;
  display: block;
  margin: 4rem auto;
}

.category-section__header {
  background-color: #f7f4f4;
  text-align: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: clamp(1rem, 2.2vw, 2.25rem);
  padding-top: 1rem;
}

.category-section__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: max(2rem, 3vw);
  column-gap: max(3rem, 4vw);
  padding: max(2rem, 3vw) max(3rem, 3vw);

  .category-section__card {
    background-color: #FFFFFF;
    text-align: center;
    // aspect-ratio: 1/2;
    padding-block: 1rem;
    border-radius: 2.1rem;
    cursor: pointer;
    transition: transform 300ms ease-in;
    color: inherit;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3), 0 0 1px 0 rgba(0, 0, 0, 0.25);
      text-decoration: none;
    }

    .category-section__img {
      width: min(25%, 6vw);
      aspect-ratio: 1/1;
      object-fit: cover;
    
      display: block;
      margin: 0 auto;
    }

    .category-section__card-content {
      align-self: center;
      justify-content: center;
      text-decoration: none;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: clamp(0.5rem, 0.9rem, 1rem);
      margin-top: 0.3rem;
      color: #5e5b5b;
    }
  }
}
