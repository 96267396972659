.content_report {
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #007aaa;
  text-align: center;
  text-decoration-line: underline;
  padding-top: 12px;
}

.content_report:hover {
  cursor: pointer;
}

.popup_window {
  margin: 10% auto;
  width: 35%;
  background-color: white;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  padding: 15px;
}

@media (max-width: 2000px) {
  .popup_window {
    margin: 13% auto;
    width: 60%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(56, 56, 56, 0.6);
}

.header_text {
  font-family: "Public Sans";
}

.functions {
  display: grid;
  margin-top: 40px;
  grid-template-columns: 15% 15% 20%;
  column-gap: 3%;

  .other_buttons {
    color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(202, 202, 202);
  }

  .other_buttons:hover {
    background-color: rgb(233, 233, 233);
    border: 1px solid rgb(202, 202, 202);
  }

  .delete_button {
    color: rgb(255, 255, 255);
    background-color: rgb(198, 59, 59);
    border: 1px solid #d8a7a7;
  }

  .delete_button:hover {
    background-color: rgb(171, 48, 48);
    border: 1px solid #d8a7a7;
  }

  .resolve_button {
    background-color: #007aaa;
  }

  .resolve_button:hover {
    background-color: #006993;
  }
}

.close_button {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  line-height: 20px;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: white;
}

.close_button:hover,
.close_button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: white;
}

.edit_box {
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  resize: none;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #bbbbbb;
  min-height: 150px;
  width: 100%;
  margin-bottom: 10px;
}