.dropDown {
  display: grid;
  max-width: fit-content;

  &:hover .dropDownContent {
    display: block;
  }

  .dropBtn {
    font-size: 18px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    box-shadow: none;
    padding: 16px;

    &:hover {
      background-color: transparent;
      border: none;
    }

    .hamburger-black {
      display: block;
      background: black;
      width: 2.2em;
      height: 5px;
      position: relative;
    }

    .hamburger-white {
      display: block;
      background: white;
      width: 2.2em;
      height: 5px;
      position: relative;
    }

    .hamburger-black::before,
    .hamburger-black::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      background: black;
      width: 2.2em;
      height: 5px;
      border-radius: 2em;
    }

    .hamburger-black::before,
    .hamburger-white::before {
      top: 12px;
    }

    .hamburger-black::after,
    .hamburger-white::after {
      bottom: 12px;
    }

    .hamburger-white::before,
    .hamburger-white::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      background: white;
      width: 2.2em;
      height: 5px;
      border-radius: 2em;
    }
  }

  .dropDownContent {
    display: none;
    color: black;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 2.1rem;
    margin-left: 2rem;

    a {
      color: black;
      padding: 14px 18px;
      display: block;

      &:hover {
        background-color: #007aaa;
        color: white !important;
        cursor: pointer;
      }
    }

    .link {
      color: #000000 !important;
    }
  }
}
