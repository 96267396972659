.wrapper {
  margin-left: 40px;
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 25px;
}

.StarRatingBar {
  margin-top: 20px;
}

.StarRatingBar span {
  float: left;
  margin-right: 10px;
}

.StarRatingBar button,
.StarRatingBar button:hover {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 30px;
  width: 30px;
  height: 5px;
}

.StarIcons {
  position: relative;
  top: -22px;
  max-height: 5px;
}

.StarActive {
  color: #ffd700;
}
.StarInactive {
  color: #ccc;
}

.ReviewInput {
  margin-top: 40px;
}

.PriceInput {
  margin-top: 20px;
  max-width: 390px;
  display: grid;
}

.PriceInput input,
.PriceInput select {
  font-family: "Public Sans";
  font-size: 14px;
  padding: 11px 8px;
  border-radius: 2px;
  border: 1px solid #bbbbbb;
}

.PriceInput input:focus {
  outline: none;
  border-color: #000000;
}

.PriceInput label {
  margin-bottom: 5px;
  float: left;
}

.Categories {
  margin-top: 25px;
  max-width: 550px;
}

.Categories button {
  margin-top: 10px;
  margin-right: 10px;
  border: 1px solid #b6cde3;
}

.ButtonNotPressed {
  background-color: #97b9d8;
}

.ButtonPressed {
  background-color: #3174b3;
}

.Checkbox {
  margin-top: 30px;
  font-weight: 500;
  display: flex;
}

.Checkbox input {
  position: relative;
  width: 18px;
  height: 18px;
  top: 2px;
}

.SubmitButton {
  margin-top: 14px;
  margin-bottom: 10px;
}

.ErrorText {
  display: grid;
  color: #b40e0e;
}

.SuccessMessage {
  color: green;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}

.FormFailure {
  max-width: 500px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  font-family: "Public Sans";
  font-size: 18px;
  color: rgb(177, 60, 60);
  background-color: #e7c9c9;
  border-radius: 5px;
  border: 1px solid #d8a7a7;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

.not_authorized {
  margin-top: 20px;
  text-align: center;
}
