.tabs-container {
  display: grid;
  padding: 0% 2% 0% 2%;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
}

/* Tab Navigation */
ul.nav {
  padding: 70px 0px 0px 0px;
  display: flex;
  border-width: 0px 0px 2px 0px;
}

ul.nav li {
  padding: 10px;
  list-style: none;
  transition: all 0.1s;
}

li[class="active"] {
  border: solid #39a2db !important;
  border-width: 0px 0px 3.5px 0px !important;
}

li[class="inactive"] {
  border: 1px solid grey !important;
  border-width: 0px 0px 1.5px 0px !important;
}

.settings-icon {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-evenly;
}
